<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">General Report</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">Purchase Report</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Day Wise
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Purchase Report</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 d-flex justify-content-end pr-2 pb-2">
        <div class="bg-theam-secondary d-flex m-2 p-1">
          <div class="report-tool-icon mr-3 ml-1">
            <i class="fas fa-print" @click="print"></i>
          </div>
          <div class="report-tool-icon mr-1">
            <i class="fas fa-download" @click="download"></i>
          </div>
        </div>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-12 d-block mr-0 pr-0">
          <select
            class="mg-t-5 mr-2"
            v-model="dayWiseParams.pageIndex"
            @change="getFilteredDataWithoutDates"
          >
            <option value="30">30</option>
            <option value="50">60</option>
            <option value="100">90</option>
            <option value="100">ALL</option>
          </select>
          <select class="mg-t-5 mr-2">
            <option
              v-for="(year, index) in sessionYears"
              :key="index"
              :value="year"
            >
              {{ year }}/{{ parseInt(year.substring(year.length - 2)) + 1 }}
            </option>
          </select>
          <select
            v-model="dayWiseParams.branch"
            class="filter-input mg-t-5 mr-2"
            @change="getFilteredDataWithoutDates"
          >
            <option value="">All Branch</option>
            <option
              v-for="(branch, index) in branches"
              :key="index"
              :value="branch.id"
            >
              {{ branch.name }}
            </option>
          </select>
          <SearchDayMonthComponent
            @clicked="monthDayClicked"
            :show-day="false"
          ></SearchDayMonthComponent>
          <div class="d-inline-block mr-2">
            <label class="filter-lavel mb-0 mr-1 mg-t-5">From</label>
            <input
              type="date"
              v-model="dayWiseParams.from_date"
              placeholder="From"
              class="filter-input mr-1 mg-t-5"
              @change="selectFromDate"
            />
            <label class="filter-lavel mb-0 mr-1 mg-t-5">To</label>
            <input
              type="date"
              v-model="dayWiseParams.to_date"
              placeholder="To"
              class="mg-t-5 filter-input"
              @change="selectEndDate"
            />
          </div>
          <!-- <div class="ml-2">
            <input
              type="text"
              v-model="filter.key"
              class="filter-input"
              placeholder="Search..."
            />
          </div> -->
          <div class="float-right mr-3 mg-t-5">
            <select
              class="filter-input ml-2 float-right"
              v-model="route"
              @change="filterReportType"
            >
              <option value="/general-report/purchase">Bills Wise</option>
              <option value="/general-report/purchase/branch-wise">
                Branch Wise
              </option>
              <option value="/general-report/purchase/day-wise">
                Day Wise
              </option>
              <option value="/general-report/purchase/month-wise">
                Month Wise
              </option>
              <option value="/general-report/purchase/supplier-wise">
                Supplier Wise
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-theam-secondary table-search-head">
      <div>
        <span class="font-s tx-bold mr-2 tx-primary"
          >Total Purchase: NPR
          {{ parseDigitForSlip(dayWiseAttributes.total_purchase) }},</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >Credit: NPR
          {{ parseDigitForSlip(dayWiseAttributes.total_due) }},</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >Bills Count: NPR
          {{ parseDigitForSlip(dayWiseAttributes.total_bills) }},</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >Import: NPR
          {{ parseDigitForSlip(dayWiseAttributes.total_import_purchase) }}</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >Local Purchase: NPR
          {{ parseDigitForSlip(dayWiseAttributes.total_local_purchase) }}</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >VAT able Purchase: NPR
          {{ parseDigitForSlip(dayWiseAttributes.total_vatable_amount) }}</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >VAT: NPR
          {{ parseDigitForSlip(dayWiseAttributes.total_vat_amount) }}</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >Order: NPR
          {{ parseDigitForSlip(dayWiseAttributes.total_purchase_orders) }}</span
        >
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-10p">Date</th>
              <th class="wd-10p">Bills Count</th>
              <th class="wd-10p">Total Purchase</th>
              <th class="wd-10p">Credit</th>
              <th class="wd-10p">Bill Amt.</th>
              <th class="wd-10p">VAT-able</th>
              <th class="wd-10p">VAT Amt.</th>
              <th class="wd-10p">Import</th>
              <th class="wd-10p">Local</th>
              <!-- <th class="wd-10p">Order</th> -->
            </tr>
          </thead>
          <tbody v-if="!loading && dayWiseDatas.length > 0">
            <tr v-for="(purchase, index) in dayWiseDatas" :key="index">
              <th scope="row" class="table-start-item">{{ ++index }}</th>
              <td>
                {{ purchase.date }}
              </td>
              <td>
                {{ purchase.total_bill_count ? purchase.total_bill_count : 0 }}
              </td>
              <td>
                {{ parseDigitForSlip(purchase.total_purchase_amount) }}
              </td>
              <td>
                {{ parseDigitForSlip(purchase.total_credit) }}
              </td>
              <td>
                {{ parseDigitForSlip(purchase.total_bill_amount) }}
              </td>
              <td>
                {{ parseDigitForSlip(purchase.total_vatable_amount) }}
              </td>
              <td>
                {{ parseDigitForSlip(purchase.total_vat_amount) }}
              </td>
              <td>
                {{ parseDigitForSlip(purchase.total_export_purchase) }}
              </td>
              <td>
                {{ parseDigitForSlip(purchase.total_local_purchase) }}
              </td>
              <!-- <td>-</td> -->
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <tr>
              <td colspan="11" style="text-align: center">
                {{ errorMessage }}
              </td>
            </tr>
          </tbody>

          <tbody v-else-if="!loading && dayWiseDatas.length == 0">
            <tr>
              <td colspan="11" style="text-align: center">No records found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="11" style="text-align: center">Loading.....</td>
            </tr>
          </tbody>
        </table>
        <Paginate
          v-model="page"
          :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Paginate from "vuejs-paginate";
import Services from "./Services/Services";
import SearchDayMonthComponent from "../../../../shared/SearchDayMonthComponent";
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  components: {
    SearchDayMonthComponent,
    Paginate,
  },
  data() {
    return {
      sessionYears: ["2021"],
      route: this.$route.path,
      branches: [],
      dayWiseParams: {
        pageIndex: 30,
        branch: "",
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        from_date: "",
        to_date: "",
        searched: "",
        offset: 0,
      },
      loading: false,
      errorMessage: "",
      error: false,
      totalPageCount: 0,
      pageReload: "",
      pageSerialNo: 1,
      page: 1,
      month: new Date().getMonth(),
    };
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists2",
      "dataLists9",
      "removeList",
      "eventMessage",
      "modalId",
    ]),
    ...mapGetters("purchaseReports", ["dayWiseDatas", "dayWiseAttributes"]),
  },
  beforeMount() {
    this.getFilteredData();
    this.pageReload = true;

    Services.getUserAccessBranches()
      .then((response) => {
        this.branches = response.data.data;
        // this.sessionYears = response.data.data.fiscalYears;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    print(){
      window.print();
    },
    //month day component clicked
    monthDayClicked(value) {
      if (value) {
        this.dayWiseParams.from_date = "";
        this.dayWiseParams.to_date = "";
        this.dayWiseParams.day = value.day;
        this.dayWiseParams.month = value.month;
        this.dayWiseParams.year = value.year;
      }
      this.page = 1;
      this.pageSerialNo = 1;
      this.dayWiseParams.offset = 0;
      //make api call
      this.getFilteredData();
    },
    filterReportType() {
      if (this.$route.path != this.route) {
        this.$router.push(this.route);
      }
    },
    // pagination clicked
    clickCallback: function (page) {
      if (page == 1) {
        this.pageSerialNo = 1;
      } else {
        this.pageSerialNo = (page - 1) * this.dayWiseParams.pageIndex + 1;
      }
      this.offset = (page - 1) * this.dayWiseParams.pageIndex;
      this.getFilteredDataWithoutDates();
    },
    selectFromDate() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.dayWiseParams.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectEndDate() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.dayWiseParams.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    searchKeyword: _.debounce(function () {
      this.page = 1;
      this.pageSerialNo = 1;
      this.dayWiseParams.offset = 0;
      this.getFilteredDataWithoutDates();
    }, 600),
    // today/month section
    getFilteredData() {
      this.loading = true;
      let params = {
        pageIndex: this.dayWiseParams.pageIndex,
        branch: this.dayWiseParams.branch,
        today: this.dayWiseParams.day,
        month: this.dayWiseParams.month + 1,
        year: this.dayWiseParams.year,
        from_date: this.dayWiseParams.from_date,
        to_date: this.dayWiseParams.to_date,
        searched: this.dayWiseParams.searched,
        page_reload: this.pageReload,
        offset: this.dayWiseParams.offset,
      };
      this.ajaxRequest(params);
    },
    // without today/month section
    getFilteredDataWithoutDates() {
      this.loading = true;
      let params = {
        pageIndex: this.dayWiseParams.pageIndex,
        branch: this.dayWiseParams.branch,
        today: "",
        month: "",
        year: this.dayWiseParams.year,
        from_date: this.dayWiseParams.from_date,
        to_date: this.dayWiseParams.to_date,
        searched: this.dayWiseParams.searched,
        page_reload: this.pageReload,
        offset: this.dayWiseParams.offset,
      };
      this.ajaxRequest(params);
    },
    ajaxRequest(params) {
      this.error = false;
      Services.getDayWiseFilterDatas(params)
        .then((res) => {
          this.$store.commit(
            "purchaseReports/setDayWiseAttributes",
            res.data.data.attributes
          );
          this.$store.commit(
            "purchaseReports/setDayWiseDatas",
            res.data.data.data
          );
          this.totalPageCount = res.data.data.total_no_of_pages;
        })
        .catch((error) => {
          this.error = true;
          console.log(error);
          if (
            !Object.prototype.hasOwnProperty.call(
              error,
              "response.data.error_message"
            )
          ) {
            if (error.response.data.error_message == "Access Denied.") {
              this.errorMessage = "Access Denied !!!";
              // this.$router.push({ name: "access-denied" });
            } else {
              this.errorMessage = "Error fetching data from the server.";
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    download(){
      Services.getDayWiseExcel(this.dayWiseParams).then((response)=>{
        const blob = new Blob([response.data], { type: 'application/vnd.ms-excel;charset=utf-8' });
        const elink = document.createElement("a");
            elink.download = 'purchase-day-wise-report.xlsx';
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL (elink.href); // release the URL object
            document.body.removeChild(elink);
      }).catch(err=>{
        console.log(err);
      });
    },
  },

  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>